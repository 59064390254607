'use strict';

var SiteConstants = require('constants/SiteConstants');
var crossSell = require('../cart/crossSell');
var metaConversion = require('../metaConversion/metaConversion');
var updateMiniCart = true;
var metaConversion = require('../metaConversion/metaConversion');

function setMiniCartBodyMaxHeight(minicart) {
    var $minicart = $(minicart);
    var $minicartScrollable = $minicart.find('.product-summary');
    var minicartHeight = $minicart.outerHeight();
    var minicartScrollableHeight = $minicartScrollable.outerHeight();
    var minicartNonScrollableHeight = minicartHeight - minicartScrollableHeight;
    var minicartOffset = $minicart.offset().top - $(window).scrollTop();
    var subtractHeight = minicartOffset + minicartNonScrollableHeight + SiteConstants.Spacer;

    $minicartScrollable.css('max-height', 'calc(100vh - ' + subtractHeight + 'px)');
}

module.exports = function () {
    $('.minicart').on('count:update', (event, count) => {
        count.quantityTotal === 0 ? $('.minicart .minicart-quantity').addClass('d-none') : $('.minicart .minicart-quantity').removeClass('d-none');
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    //PAP-4674: FE - Improve Cart Popup Modal Design
    $('.minicart.flyout-enabled').on('click', function () {
        var url = $('.minicart.flyout-enabled').data('action-url');
        var count = parseInt($('.minicart.flyout-enabled .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart.flyout-enabled [data-flyout-wrapper].flyout-show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart.flyout-enabled [data-flyout-wrapper]').addClass('flyout-show');
                return;
            }

            $('.minicart.flyout-enabled [data-flyout-wrapper]').addClass('flyout-show');
            $('.minicart.flyout-enabled [data-flyout-wrapper]').spinner().start();
            $.get(url, function (data) {
                $('.minicart.flyout-enabled [data-flyout-wrapper]').empty();
                $('.minicart.flyout-enabled [data-flyout-wrapper]').append(data);
                $('[data-minicart-count] .minicart-quantity').text($('.minicart-total [data-flyout-pick]').text());
                metaConversion.callMetaConversionAPI(null , 'Cart-Show');
                crossSell.init();
                $('body').trigger('minicart:reinitPaypal');
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('click','.minicart-swipe-up-overlay', function (){
        $(this).css('display','none');
        $('.flyout-wrapper .minicart-footer').removeClass('swipe-up');
        $('.flyout-wrapper .checkout-continue .swipe-up-minicart-btn').css('display','inline');
    });

    $('body').on('click','.flyout-wrapper .checkout-continue .swipe-up-minicart-btn', function (e){
        $('.flyout-wrapper .minicart-footer').addClass('swipe-up');
        $('.minicart-swipe-up-overlay').css('display','block');
        $(this).css('display','none');
    });
    //PAP-4674: FE - Improve Cart Popup Modal Design


    //PAP-2238: Remove Mini-Cart load on Desktop Hover
    $('.minicart').off('mouseenter focusin touchstart');

    // Close .popover when clicking outside
    $('body').on('click touchstart', event => {
        if ($('.minicart .popover').has(event.target).length === 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    $('body').on('minicart:reinitPaypal', function () {
        //reinitialize paypal script to render minicart buttons
        if ($('.minicart.flyout-enabled .js_paypal-content').length && $('.minicart.flyout-enabled [data-flyout-wrapper] .paypal-buttons').length == 0) {
            var ppScript = $('.minicart.flyout-enabled [data-flyout-wrapper]').find('script.pp-script');
            if (ppScript.length) {
                $('.js_paypal_button_on_cart_page').empty();
                var scriptSrc = ppScript.attr('src');
                $('script[src="' + scriptSrc + '"]').remove();
                var newScript = document.createElement('script');
                newScript.src = scriptSrc;
                newScript.async = true;
                document.body.appendChild(newScript);
            }
        }
    });

    $('.minicart').on('mouseleave focusout', event => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });

    // Remove event handlers that close .popover on focusout or mouseleave
    $('.minicart').off('mouseleave focusout');

    // made changes here for mini cart escape key for ticket PAP-939
    document.addEventListener('keydown', function(event){
        if(event.key === "Escape"){
            $('.minicart .popover').removeClass('show');
        }
    });

    $(document).on('focusin', '.navbar-nav .nav-link', function(){
        $('.minicart .popover').removeClass('show');
    });
    
    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart cart:update', () => {
        updateMiniCart = true;
    });

    $('body').on('minicart:loaded', (event, minicart) => {
        setMiniCartBodyMaxHeight(minicart);
    });
};
