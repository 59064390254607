'use strict';

/**
 * Calls the Meta Conversion API to track an event.
 * 
 * @param {string} prodID - The ID of the product involved in the conversion event.
 * @param {string} event - The type of event (e.g., 'purchase', 'add_to_cart', etc.).
 */
function callMetaConversionAPI(prodID, event) {
    var trackConversionURL = $('#trackConversionURL').val();
    if (trackConversionURL) {
        var trackingConversionURL = $('#trackConversionURL').val().split("?")[0];
        var form = {};
        form.currentURL = window.location.href;
        form.isAjax = true;
        form.objID = prodID;
        form.event = event;
        $.ajax({
            url: trackingConversionURL,
            data: form,
            method: 'POST'
        });
    }
}

module.exports = {
    /**
     * Tracks the conversion by sending the current page URL to the Meta Conversion API.
     * This function does not require any parameters, and will execute if the `trackConversionURL` is available.
     */
    trackConversion: function () {
        var trackConversionURL = $('#trackConversionURL').val();
        if (trackConversionURL) {
            var form = {};
            form.currentURL = window.location.href;
            $.ajax({
                url: trackConversionURL,
                data: form,
                method: 'POST',
                success: function (data) {
                    if (data.eventType == 'InitiateCheckout') {
                        $("input[name='gtmCheckoutData']").attr('data-gtmcheckoutdata' , data.gtmData);
                    } else if (data.eventType == 'ProductView') {
                        $("input[name='gtmviewcontentdata']").attr('data-gtmviewcontentdata' , data.gtmData);
                    }
                }
            });
        }
    },
    
    /**
     * Calls the Meta Conversion API to track an event for a specific product.
     * 
     * @param {string} prodID - The ID of the product involved in the conversion event.
     * @param {string} event - The type of event (e.g., 'purchase', 'add_to_cart', etc.).
     */
    callMetaConversionAPI: callMetaConversionAPI
};
