'use strict';
var focusHelper = require('base/components/focus');
var formValidation = require('base/components/formValidation');
//PAP-3146: include script only on checkout page
if ($('#paymentMethodsList').length) {
    var adyenCheckout = require('../integrations/adyen/adyenCheckout');
}

var crossSell = require('../cart/crossSell');
/**
 * Retrieves the relevant pid value (copied from base so we dont load the entire file 2x for this)
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    if(data && data.totals && data.totals.isFreeShipping){
        $('.shipping-cost span').empty().append($('.free-shipping-label').text());
    } else {
        $('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    }
    $('.tax-total span').empty().append(data.totals.totalTax);
    $('.grand-total span').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $('.grand-total-sum').attr('data-grand-total-sum', data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue);
    $('.sub-total span').empty().append(data.totals.subTotal);
    if($('.minicart').hasClass('flyout-enabled')) {
        $('.minicart-item-total-number').empty().append(data.numItems);
    }
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.cartHasGiftWrap) {
        $('.cart-sub-total').empty().append(data.subTotalWithoutGiftWrap);
        $('.gift-wrap-subtotal-price').empty().append(data.giftWrapPrice);

        $('.gift-wrap-subtotal').removeClass('d-none');
    } else {
        $('.gift-wrap-subtotal').addClass('d-none');

        $('.cart-sub-total').empty().append(data.totals.subTotal);
    }

    $('.shipping-promotion-applied').empty();
    if (data.totals.shippingLevelDiscountTotal.value > 0 && (!data.totals.isFreeShipping)) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.totals.discounts.forEach(function (discount) {
        if(discount.type == 'promotion'){
            var html='';
            var shippingPromotionMsg = $('.shipping-promotion-msg').text();
            html += '<div class="congrats-msg"><div class="content-asset">'+shippingPromotionMsg+'</div></div><div class="progress shipping-completed"><div class="progress-bar" style="width:100%" role="progressbar" aria-valuenow="4" aria-valuemin="0" aria-valuemax="30"></div></div><div>'+discount.callOutMsg+'</div>'
            $('.shipping-promotion-applied').append(html);
            $('.shipping-promotion-applied').removeClass('d-none');
        }
    });

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $('.giftcertificate-discount').removeClass('d-none');
        $('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $('.giftcertificate-discount-total').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $('.giftcertificate-discount').addClass('d-none');
    }

    var giftCardConfigurations = window.CachedData.giftCardSiteIntegrations;
    var hasGiftCard = $('div.hasGiftCard').length;
    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        if(!hasGiftCard) {
            $('.cartAdditionalPaymentButtons').removeClass('d-none');
            $('.js-googlepay-hide').removeClass('d-none');
        }
    } else {
        $('.js-googlepay-hide').addClass('d-none');
        $('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
    //PAP-2131: update shipping methods on cart page
    if (data.shipments.length > 0 && data.shipments[0].shippingMethods.length > 0 && $('#shippingMethods').length > 0) {
        $('#shippingMethods').empty();
        data.shipments[0].shippingMethods.forEach(function (shippingMethod) {
            if (shippingMethod.ID == 'ABWP') {
                return;
            }
            if (shippingMethod.estimatedArrivalTime) {
                var shippingMethodName = shippingMethod.displayName + ' (' + shippingMethod.estimatedArrivalTime + ')';
            } else {
                var shippingMethodName = shippingMethod.displayName;
            }
            var options = $('<option></option>').attr('data-shipping-id', shippingMethod.ID).text(shippingMethodName);
            if (data.shipments[0].selectedShippingMethod == shippingMethod.ID) {
                options.attr('selected', 'selected')
            }
            $('#shippingMethods').append(options);
        });
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item, index) => {
            if(index == 0){ // PAP-1910 we need only one promotion msg
                var progressBar = item.shippingDiscountApproachPercent;
                html += '<div class="single-approaching-discount text-center">'+ item.discountMsg +'<div class="progress"><div class="progress-bar" style="width:'+progressBar+'%" role="progressbar" aria-valuenow="4" aria-valuemin="0" aria-valuemax="30"></div></div><div>'+item.callOutMsg+'</div></div>';
            }
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate && (lineItem.availability.isPreorder || lineItem.availability.isBackorder)) {
                messages += '<p class="line-item-attributes line-item-instock-date"> '
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card .product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} setId - optional setId for Paired Product functionality
 */
function confirmDelete(actionUrl, productID, productName, uuid, setId) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    if (setId) {
        $deleteConfirmBtn.data('setid', setId);
    }

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Updates the checkout view when user will
 * coupon on checkout page.
 * @param {Object} data - AJAX response from the server
*/
function updateCheckoutViewForCoupon(data, applyCoupon) {

    if(data.totals && !data.totals.grandTotalValueOrNull && data.totals.grandTotalValueOrNull==0) {
        $('.payment-information').addClass('d-none');
        $('.credit-card-selection-new').addClass('d-none');
        $(".submit-payment").show();
        $('.address-selector-block').addClass('d-none');
        $('.billingAddressFields').addClass('d-none');
        $('.giftcard-information').addClass('d-none');        
        if ($('div.razorpay-payment-button').length) {
            $(".submit-payment").show();
        }
    } else {
        $('.payment-information').removeClass('d-none');
        $('.credit-card-selection-new').removeClass('d-none');
        if($("#restPaypalAccountsList").val() == 'newaccount' && !$('.adyen-component-content').hasClass('active')){
            $(".submit-payment").hide();
        } else{
            $(".submit-payment").show();
        }
        $('.address-selector-block').removeClass('d-none');
        $('.billingAddressFields').removeClass('d-none');
        $('.giftcard-information').removeClass('d-none');
        //PAP-3146: start
        if (applyCoupon && $('#rb_klarna_account').length) {
            //PAP-3146 - update payment methods when coupon is applied and klarna pay over time payment method exists
            adyenCheckout.methods.renderGenericComponent();
        } else if (!applyCoupon && $('#paymentMethodsList').length && !$('#rb_klarna_account').length) {
            //PAP-3146 - update payment methods when coupon is removed and klarna pay over time payment method doesn't exists
            adyenCheckout.methods.renderGenericComponent();
        }
        //PAP-3146 - end
        if ($('div.razorpay-payment-button').length) {
            $(".submit-payment").hide();
        }
    }
}

function init () {
    // Get the value of the minicart-quantity, and hide or show based on value
    var cartQuantity = document.querySelector('.minicart-quantity');
    if(cartQuantity) {
        var cartQuantityValue = cartQuantity.innerHTML;
        var trimmedValue = cartQuantityValue.replace(/\s+/g, '');
        if (trimmedValue === '0') {
            cartQuantity.classList.add('d-none');
        } else {
            cartQuantity.classList.remove('d-none');
        }
    }

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var setId  = $(this).data('setid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid, setId);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        let homeURL = $(".logo-home").attr("href");
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        var setId = $(this).data('setid');

        if (setId) {
            urlParams.setId = setId;
        }

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    cartQuantity.classList.add('d-none');
                    if($('.minicart').hasClass('flyout-enabled')) {
                        $('.cart .minicart-content-wrapper').empty().addClass('d-none');
                        $('.cart .empty-minicart-content-wrapper').removeClass('d-none');
                        $('.minicart-item-total-number').empty().append(data.basket.numItems);
                    } else {
                        $('.cart').empty().append('<div class="row"> ' +
                            '<div class="col-12 text-center"> ' +
                            '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                            '</div> ' +
                            '</div>'
                        );
                    }
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (!data.hasPreOrderItems && $('.preorder-cart-msg').length > 0) {
                        $('.preorder-cart-msg').addClass('d-none');
                    }                    
                    cartQuantity.classList.remove('d-none');
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    $('.uuid-' + uuid).closest('.card').remove();
                    if($('.minicart').hasClass('flyout-enabled')) {
                        $('.minicart-item.uuid-' + uuid).remove();
                    }
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    if(!data.basket.totals.isFreeShipping){
                        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    }
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                    //PAP-2998 start: if basket has bonus product compare and reload cart page
                    if ($('.cart-page').length && data.basket.hasBonusProduct) {
                        module.exports.cartBonusProducts(data.basket.items);
                    }
                    //PAP-2998 end
                }
                var mcAnalyticsEnabled =  $('.mcEnableAnalytics').data('sfmc-mcenableanalytics');
       			if (mcAnalyticsEnabled) {
					if (_etmc && data.sfmcBasketInfo) {
						_etmc.push(["trackCart", data.sfmcBasketInfo]);
			    	}
			    }
                $('body').trigger('cart:update', data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var $select = $stepper.closest('.quantity-form').find('.quantity');
        var value = parseInt($stepper.find('input').val());

        $select.find('option[value="' + value + '"]').prop('selected', true).change();
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        if($('.minicart').hasClass('flyout-enabled')) {
            $(this).parents('.minicart-item').spinner().start();
        }
        

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                if(!data.totals.isFreeShipping){
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                }
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);
                var mcAnalyticsEnabled =  $('.mcEnableAnalytics').data('sfmc-mcenableanalytics');
				if (mcAnalyticsEnabled) {
					if (_etmc && data.sfmcBasketInfo) {
						_etmc.push(["trackCart", data.sfmcBasketInfo]);
			    	}
			    }
                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
                //PAP-2998 start: if basket has bonus product compare and reload cart page
                if ($('.cart-page').length && data.hasBonusProduct) {
                    module.exports.cartBonusProducts(data.items);
                }
                //PAP-2998 end
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $(document).on('change', '.shippingMethods', function (e) {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    /* Turn promo submission into an ajax call instead of for so we can include it inside payment form */
    function promoCodeFunction(){
        $.spinner().start();
        var $form = $('.promo-code-btn').closest('.promo-code-form');
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $form.find('.form-control').addClass('is-invalid').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        $form.find('.form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        var dataParams = encodeURI('couponCode=' + $('#couponCode').val() + '&csrf_token='+$('input[name=csrf_token]').val());
        $.ajax({
            url: $form.data('url'),
            type: 'GET',
            dataType: 'json',
            data: dataParams, 
            success: function (data) {
                if (data.error) {
                    $form.find('.form-control .form-control').addClass('is-invalid').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage).show();
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    $('body').trigger('promotion:success', data);
                    $('.coupon-error-message').hide();
                }
                $('.coupon-code-field').val('');
                updateCheckoutViewForCoupon(data, true);
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                $.spinner().stop();
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    }

    $('.shipping-promotion-applied').filter(function() {
        return $.trim($(this).text()) === ''
    }).addClass("d-none");

    $(document).on('click', '.promo-code-btn', function (e) {
        e.preventDefault();
        promoCodeFunction();
    });

    $(document).on("keyup", ".promo-code-btn", function (e) {
        if (e.which == 13){
            promoCodeFunction();
        }  
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.validateBasket(data);
                updateCheckoutViewForCoupon(data, false);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
                // Reload the page if razorpay object is created
                if ('isRazorpayOrderCreated' in data && data.isRazorpayOrderCreated) {
                    window.location.reload();
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option-value-id', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        form.pid = module.exports.getPidValue($(this))

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    //reload page in case of physical option product
                    if (data.hasOwnProperty('isPhysicalOptionProduct') && data.isPhysicalOptionProduct) {
                        location.reload();
                    }
                    $('#quickViewModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    module.exports.updateCartTotals(data.cartModel);
                    module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    module.exports.updateAvailability(data.cartModel, form.uuid);
                    module.exports.updateProductDetails(data, form.uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    module.exports.validateBasket(data.cartModel);

                    $('body').trigger('cart:update', [data, form.uuid]);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });
    crossSell.init();

    $('body').on('click','.checkout-swipe-up-overlay', function (){
        $(this).css('display','none');
        $('.card-footer.checkout-continue').removeClass('swipe-up');
        $('.checkout-continue .swipe-up-checkout-btn').css('display','inline');
    });

    $('body').on('click','.checkout-continue .swipe-up-checkout-btn', function (e){
        $('.card-footer.checkout-continue').addClass('swipe-up');
        $('.checkout-swipe-up-overlay').css('display','block');
        $(this).css('display','none');
    });

    $('body').on('change', '.gift-wrap-toggle', function(e) {
        var pid = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var toggleGiftWrapUrl = $(this).data('action');
        var checked = $(this).is(':checked');

        var form = {
            pid: pid,
            uuid: uuid,
            toWrap: checked
        };
        
        if (toggleGiftWrapUrl) {
            $.ajax({
                url: toggleGiftWrapUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    module.exports.updateCartTotals(data.cartModel);

                    $.spinner().stop();
                },
                error: function (err) {
                    $('.gift-wrap-toggle').prop('checked', false);

                    $.spinner().stop();
                }
            });
        }
    });

    $('body').on('click', '.gift-wrap-trigger-modal', function(e) {   
        $.spinner().start();
        getModalHtmlElement();
        var contentUrl = $(this).data('url');
        $.ajax({
            url: contentUrl,
            type: 'get',
            dataType: 'html',
            success: function (htmlResponse) {
                $('#editProductModal .modal-body').empty().html(htmlResponse).addClass('giftwrap');
                $('#editProductModal .modal-dialog').addClass('giftwrap-dialog');
                $('#editProductModal').modal('show');
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
    //PAP-2862 start: Prod - Missing Popular Addons
    if ($('.cart #product-addons-recommendations').length && $('.cart #product-addons-recommendations').find('div.product').length == 0) {
        //hide addons section from cart if no product rendered
        $('.cart #product-addons-recommendations').addClass('d-none');
    }
    //PAP-2862 end
}

/**
 * Generates the modal window on the first call.
 *
 */
 function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" role="dialog">'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<h2 class="modal-title">Gift Wrap</h2>'
        + '    <button aria-label="Close dialog" type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only">Close Cart Gift wrap Dialog </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer border-0"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * PAP-2998: compare bonus product in basket with cart page and reload page if bonus product not rendered/removed
 *
 */
function cartBonusProducts(items) {
    if (items) {
        var bonusProductCount = 0;
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.isBonusProductLineItem) {
                bonusProductCount++;             
            }
        }
        //reload page if any bonus product is not rendered
        if ($('.bonus-product').length != bonusProductCount) {
            location.reload();
        }
    }
}

module.exports = {
    getPidValue: getPidValue,
    appendToUrl: appendToUrl,
    validateBasket: validateBasket,
    updateCartTotals: updateCartTotals,
    createErrorNotification: createErrorNotification,
    updateApproachingDiscounts: updateApproachingDiscounts,
    updateAvailability: updateAvailability,
    updateProductDetails: updateProductDetails,
    confirmDelete: confirmDelete,
    cartBonusProducts: cartBonusProducts,
    init: init
};
